
import 'bootstrap/dist/css/bootstrap.min.css';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { getDecoded } from './AuthWrapper';

//import NavDropdown from 'react-bootstrap/NavDropdown';

function Navigation(props) {
  var body = getDecoded();


  return (
    <Navbar expand="lg" className="bg-body-tertiary" style={{ marginBottom: "20px" }}>
      <Container>
        <Navbar.Brand href="/#"><img src="./favicon.png" height="25px"/> Platsspårning</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto"></Nav>
          <form className="form-inline my-2 my-lg-0 float-right" action="/logout" method="POST">
            <span className="navbar-text my-2 my-sm-0">
              Inloggad som {body.sub} &nbsp;
            </span>
            <button type="button" className="btn btn-outline-secondary my-2 my-sm-0" onClick={(e) => props.logout.logoutFunc()}>Logga ut</button>
          </form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;